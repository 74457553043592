var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
// Lib
import React, { lazy, Suspense } from "react";
//Components
var SearchPopout = lazy(function () { return import('../components/SearchPopout'); });
var MiniSearch = /** @class */ (function (_super) {
    __extends(MiniSearch, _super);
    function MiniSearch() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.changeLatLng = function (resp) {
            var latLng = [resp.geometry.location.lat || null, resp.geometry.location.lng || null].join(', ');
            var url = "?s=&page=1&latLng=".concat(latLng, "&radius=35000&area=").concat(resp.name);
            window.location.assign("".concat(window.location.protocol, "//").concat(window.location.hostname, "/").concat(url));
        };
        return _this;
    }
    MiniSearch.prototype.renderFallback = function () {
        return (React.createElement("input", { type: "text", disabled: true, className: "form__control" }));
    };
    MiniSearch.prototype.render = function () {
        var fallback = this.renderFallback();
        return (React.createElement(Suspense, { fallback: fallback },
            React.createElement(SearchPopout, { searchTerm: null, onSelected: this.changeLatLng })));
    };
    return MiniSearch;
}(React.PureComponent));
export default MiniSearch;
